function routeStartsWith(location, routes) {
  let routeArray = routes;
  if (!Array.isArray(routes)) {
    routeArray = [routes];
  }
  return routeArray.some((route) => {
    return location.pathname.startsWith(`/m/${route}`);
  });
}

function mobileNativeRouteStartsWith(location, routes) {
  // Unique path only used for mobile native pages
  let routeArray = routes;
  if (!Array.isArray(routes)) {
    routeArray = [routes];
  }
  return routeArray.some((route) => {
    return location.pathname.startsWith(`/cm/${route}`);
  });
}

function preAuthRouteStartsWith(location, routes) {
  // Unique path only used for pre-authenticated features
  let routeArray = routes;
  if (!Array.isArray(routes)) {
    routeArray = [routes];
  }
  return routeArray.some((route) => {
    return location.pathname.startsWith(`/l/${route}`);
  });
}

export function loginUI(location) {
  return onLoginPage(location);
}

export function onLoginPage(location) {
  return routeStartsWith(location, ["login", "logout", "oauth", "select-user"]);
}

export function mobilePaymentsUI(location) {
  return (
    routeStartsWith(location, ["payments"]) ||
    mobileNativeRouteStartsWith(location, ["payments"])
  );
}

export function preAuthUnauthorized(location) {
  return preAuthRouteStartsWith(location, ["unauthorized"]);
}

export function preAuthUI(location) {
  return preAuthRouteStartsWith(location, [
    "engagement",
    "error",
    "esign",
    "files",
    "invoice",
    "invoices",
    "upload",
    "questionnaire",
    "document-checklist",
  ]);
}

export function clientUI(location) {
  if (onLoginPage(location)) {
    // MFA uses /m/login/{MFARoute} and needs to be excluded
    return false;
  } else if (window.loggedInUser?.role === "Client") {
    //TODO: Need to implement a better long term default route for clients
    return location.pathname.startsWith("/m");
  } else if (
    window.loggedInUser?.role === "TeamMember" &&
    routeStartsWith(location, ["contacts", "clients"])
  ) {
    window.location.href = "/#/403";
  }

  return false;
}

export function webviewUI(location) {
  return mobileNativeRouteStartsWith(location, ["webview"]);
}
