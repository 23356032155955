import * as singleSpa from "single-spa";
import { calculatePublicPath } from "canopy-sofe-extensions";
import * as isActive from "./child-app-active.functions.js";

import bootstrapSpa from "./bootstrap/spa-bootstrapper.js";
import { handleError } from "./handle-error.js";

__webpack_public_path__ = calculatePublicPath("mobile-root-config");

bootstrapSpa()
  .then(() => {})
  .catch((err) => {
    handleError(err);
  })
  .finally(() => {
    // register each application so they can be fetched
    // but wait for blocking promises to complete before starting singleSpa
    singleSpa.start();
  });

singleSpa.registerApplication(
  "login-ui",
  () => SystemJS.import("login-ui!sofe").then((mod: any) => mod.mobileLifecycles),
  isActive.loginUI
);

singleSpa.registerApplication(
  "billing-ui",
  () =>
    SystemJS.import("billing-ui!sofe").then(
      (mod: any) => mod.mobilePaymentsLifecycles
    ),
  isActive.mobilePaymentsUI
);

singleSpa.registerApplication(
  "pre-auth-ui",
  SystemJS.import("pre-auth-ui!sofe"),
  (location) =>
    isActive.preAuthUI(location) || isActive.preAuthUnauthorized(location)
);

singleSpa.registerApplication(
  "client-portal",
  () => SystemJS.import("client-portal!sofe"),
  isActive.clientUI
);

singleSpa.registerApplication(
  "webview-ui",
  () => SystemJS.import("webview-ui!sofe"),
  isActive.webviewUI
);
